<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <b-form-group :label="$t('Dil Seçiniz')" label-for="selectedLanguage">
            <v-select
              v-model="selectedLanguage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dilOptions"
              :reduce="(val) => val.value"
              @input="fetchUrunAciklama"
              input-id="selectedLanguage"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="6">
          <b-form-group :label="$t('Sıra')" label-for="ldSira">
            <b-form-input v-model="urunAciklamaData.ldSira" type="text" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Başlık')" label-for="sBaslik">
            <b-form-input v-model="urunAciklamaData.sUrunBaslik" type="text" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="URL" label-for="sUrl">
            <b-form-input
              v-model="urunAciklamaData.sUrl"
              type="url"
              :placeholder="$t('URL Giriniz')"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="URL" label-for="sUrl">
            <b-form-input v-model="url" type="text" disabled />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12" md="12" lg="12">
          <b-form-group :label="$t('Etiketler')" label-for="etiketList">
            <b-form-tags
              v-model="etiketList"
              :placeholder="$t('Etiketleri enter ile giriniz')"
              remove-on-delete
              input-id="etiketList"
              class="mb-2"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12" md="12" lg="12">
          <b-form-group :label="$t('Açıklama')" label-for="sAciklama">
            <b-form-textarea
              v-model="urunAciklamaData.sAciklama"
              id="sAciklama"
              :placeholder="$t('Açıklama')"
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12" md="12" lg="12">
          <label class="mb-1">{{ $t("Özel Alan") }} 1</label>
          <editor
            v-if="!isLoading"
            v-model="urunAciklamaData.sOzelAlan1"
            api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
            :init="{
              plugins: [
                'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage textcolor colorpicker',
              ],
              toolbar:
                'undo redo | bold italic underline strikethrough | \
                fontselect fontsizeselect formatselect | \
                alignleft aligncenter alignright alignjustify | outdent indent | \
                numlist bullist checklist | \
                forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | \
                fullscreen  preview  print | insertfile image media pageembed link anchor | \
                a11ycheck ltr rtl | showcomments addcomment | uploadimage',
              language: 'tr_TR',
              min_height: 500,
              valid_elements: '*[*]',
              extended_valid_elements: 'style,link[href|rel]',
              custom_elements: 'style,link,~link',
              force_br_newlines: true,
              forced_root_block: false,
            }"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12" md="12" lg="12">
          <label class="mb-1">{{ $t("Özel Alan") }} 2</label>
          <editor
            v-if="!isLoading"
            v-model="urunAciklamaData.sOzelAlan2"
            api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
            :init="{
              plugins: [
                'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage textcolor colorpicker',
              ],
              toolbar:
                'undo redo | bold italic underline strikethrough | \
                fontselect fontsizeselect formatselect | \
                alignleft aligncenter alignright alignjustify | outdent indent | \
                numlist bullist checklist | \
                forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | \
                fullscreen  preview  print | insertfile image media pageembed link anchor | \
                a11ycheck ltr rtl | showcomments addcomment | uploadimage',
              language: 'tr_TR',
              min_height: 500,
              valid_elements: '*[*]',
              extended_valid_elements: 'style,link[href|rel]',
              custom_elements: 'style,link,~link',
              force_br_newlines: true,
              forced_root_block: false,
            }"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <save-button
            :onClickSave="saveUrunAciklama"
            :showSaveAndClose="false"
          />
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import Editor from '@tinymce/tinymce-vue'
import SaveButton from '@/components/SaveButton.vue'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

// Options
import StaticOptions from '@/common/options/StaticOptions';

export default {
  components: {
    Editor,
    SaveButton,
  },
  directives: {
    Ripple,
  },
  props: {
    urunData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      dilOptions: StaticOptions.dilOptions,
      selectedLanguage: 'tr',
      isLoading: false,
      etiketList: [],
      urunAciklamaData: {
        urun_Id: this.urunData.id,
        ldSira: 1,
        sAciklama: null,
        sEtiketler: null,
        sUrunBaslik: null,
        sOzelAlan1: null,
        sOzelAlan2: null,
      },
    }
  },
  methods: {
    fetchUrunAciklama() {
      this.isLoading = true;
      if (!this.selectedLanguage) {
        return;
      }

      const queryParams = {
        urunId: this.urunData.id,
        sDilKodu: this.selectedLanguage,
      };

      this.$store.dispatch('fetchUrunAciklama', queryParams)
        .then(response => {
          if (response.statusCode === 200) {
            this.urunAciklamaData = response.result;
            if (this.urunAciklamaData.sEtiketler) {
              this.etiketList = this.urunAciklamaData.sEtiketler.split(',')
            } else {
              this.etiketList = []
            }
          } else {
            AlertService.error(this, response.message);
          }
          this.isLoading = false;
        });
    },

    saveUrunAciklama() {
      this.urunAciklamaData.urun_Id = this.urunData.id;
      this.urunAciklamaData.sDilKodu = this.selectedLanguage;
      if (this.etiketList.length) {
        this.urunAciklamaData.sEtiketler = this.etiketList.join()
      }

      this.urunAciklamaData.sUrl = this.url === '' ? null : this.url;
      this.$store.dispatch('saveUrunAciklama', this.urunAciklamaData)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    urlFormat(url) {
      if (!url) url = '';
      url = url.toLowerCase();
      const trToEng = {
        ö: 'o',
        ç: 'c',
        ğ: 'g',
        ü: 'u',
        ı: 'i',
        ş: 's',
      };

      for (const key in trToEng) {
        if (Object.hasOwnProperty.call(trToEng, key)) {
          url = url.replaceAll(key, trToEng[key]);
        }
      }
      return url.replaceAll(/\s+/g, '-').replaceAll(/[^a-zA-Z\d\s:-]/g, '')
    },

    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.urunAciklamaData))
    },
  },

  computed: {
    url() {
      return this.urlFormat(this.urunAciklamaData.sUrl);
    },
  },
  mounted() {
    this.fetchUrunAciklama();
  },
}
</script>

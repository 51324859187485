<template>
  <v-select
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :value="grupSelect"
    :options="selectionUrunGrupList"
    class="grup-filter-select"
    :reduce="val => val.value"
    @input="(val) => $emit('update:grupSelect', val)" 
    :placeholder="$t('Ürün Grup Seç')">
    <template #selected-option="{ label }">
      <span class="text-truncate overflow-hidden">
        {{ label }}
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    grupSelect: {
      type: [Number, null],
      default: null,
    },
  },
  computed: {
    selectionUrunGrupList() {
      return this.$store.getters.getSelectionUrunGrups
    },
  },
  methods: {
    fetchSelectionUrunGrups() {
      this.$store.dispatch('fetchSelectionUrunGrups')
    },
  },
  created() {
    this.fetchSelectionUrunGrups();
  },
}
</script>

<style lang="scss" scoped>
.grup-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <!-- form -->
      <b-form class="mt-2">

        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group>
            <label label-for="sUrunKodu">{{ $t('Ürün Kodu') }}</label>
            <validation-provider #default="{ errors }" rules="required" name="Ürün Kodu" vid="sUrunKodu">
              <b-form-input
                v-model="optionsLocal.sUrunKodu"
                placeholder="Ürün Kodu" disabled
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group>
            <label label-for="sUrunKodu">{{ $t('Ürün Adı') }}</label>
            <validation-provider #default="{ errors }" rules="required" name="Ürün Adıı" vid="sUrunAdi">
              <b-form-input
                v-model="optionsLocal.sUrunAdi"
                placeholder="Ürün Adı" disabled
                :state="errors.length > 0 ? false:null"
              />
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group>
            <label label-for="ldVergiOrani">{{ $t('Vergi Oranı') }}</label>
              <b-form-input disabled
                v-model="optionsLocal.ldVergiOrani"
                type="number"
              />

            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group>
            <label label-for="ldBirimFiyat">{{ $t('Birim Fiyat') }}</label>
              <b-form-input disabled
                v-model="optionsLocal.ldBirimFiyat"
                type="number"
                step="any"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Durumlar -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group
              label=""
              label-for="contact-options"
              label-class="mb-1">
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="optionsLocal.isActive"
                  class="custom-control-primary">
                  {{ $t('Aktif Durumu') }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="optionsLocal.dbIsActive"
                  class="custom-control-primary">
                  {{ $t('Veri Tabanı Durumu') }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="optionsLocal.isShowInWebSite"
                  class="custom-control-primary">
                  {{ $t('Website Gösterim Durumu') }}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group>
              <label label-for="ldBirimFiyat">{{ $t('Ürün Grubu') }}</label>
              <urun-grup-selection :grupSelect.sync="optionsLocal.urunGrup_Id" />
            </b-form-group>
          </b-col>
        </b-row>
        
        <b-row>
          <b-col cols="12">
            <save-button 
                :onClickSave="saveUrunGenel"
                :showSaveAndClose="false"
                />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SaveButton from '@/components/SaveButton.vue';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'
import UrunGrupSelection from '../../selection/UrunGrupSelection.vue';

export default {
  components: {
    UrunGrupSelection,
    SaveButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    urunGenelData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      optionsLocal: JSON.parse(JSON.stringify(this.urunGenelData)),
      profileFile: null,
    }
  },
  methods: {
    saveUrunGenel() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('saveUrunGenel', this.optionsLocal)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      });
    },
    
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.urunGenelData))
    },
  },
}
</script>

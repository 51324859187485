<template>
  <b-card>
    <b-row>
      <b-col cols="12" md="6" lg="6">
        <b-button
          size="sm"
          variant="danger"
          @click="addNew()">
          <feather-icon icon="PlusIcon" class="mr-50" size="14" />
          <span class="align-middle">
            {{ $t('Yeni Açıklama') }}
            </span>
        </b-button>
        <h4 class="mt-2 mb-2">
            {{ $t('Bağlı Olmayan Açıklamalar') }}
          </h4>
        <b-table
          class="position-relative"
          :items="bagliOlmayanList"
          responsive
          :fields="bagliOlmayanTableColumns"
          primary-key="id"
          show-empty
          empty-text="Kayıt bulunamadı"
          outlined>
          <template #cell(sAciklama)="data">
            <div class="font-weight-bolder" v-html="data.item.sAciklama" />
          </template>
          <template #cell(actions)="data">
            <b-button
              size="sm"
              variant="primary"
              @click="baglaToggle(data.item.id, true)"
            >
              <feather-icon icon="ArrowRightCircleIcon" class="mr-50" size="14" />
              <span class="align-middle">
            {{ $t('Ürüne Bağla') }}
                </span>
            </b-button>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12" md="6" lg="6">
        <h4 class="mt-4 mb-2">
            {{ $t('Bağlı Açıklamalar') }}
          </h4>
        <b-table
          class="position-relative"
          :items="bagliList"
          responsive
          :fields="bagliTableColumns"
          primary-key="id"
          show-empty
          empty-text="Kayıt bulunamadı"
          outlined>
          <template #cell(sAciklama)="data">
            <div class="font-weight-bolder" v-html="data.item.sAciklama" />
          </template>
          <template #cell(actions)="data">
            <b-button
              size="sm"
              variant="danger"
              @click="baglaToggle(data.item.id, false)"
            >
              <feather-icon icon="ArrowLeftCircleIcon" class="mr-50" size="14" />
              <span class="align-middle">
            {{ $t('Bağ Kaldır') }}
                </span>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <save-teklif-aciklama v-if="modalObject.showModal" :modalObject="modalObject" />
  </b-card>
</template>

<script>
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue';
// Alert
import AlertService from '@/common/alert.service';

import SaveTeklifAciklama from '../../tanim-yonetim/teklifaciklama/SaveTeklifAciklama.vue';

export default {
  components: {
    SaveTeklifAciklama,
  },
  props: {
    urunData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      bagliTableColumns: [
        { key: 'sAciklama', label: 'Açıklama' },
        { label: 'Aksiyonlar', key: 'actions', class: 'text-right' },
      ],
      bagliOlmayanTableColumns: [
        { key: 'sAciklama', label: 'Açıklama' },
        { label: 'Aksiyonlar', key: 'actions', class: 'text-right' },
      ],

      bagliList: [],
      bagliOlmayanList: [],

      modalObject: {
        item: {},
        showModal: false,
        onSuccess: this.onSuccessBagEkle,
      },
    };
  },
  methods: {
    onSuccessBagEkle() {
      this.fetchBagli(false);
    },
    fetchBagli(bagDurum) {
      const queryParams = {
        urunId: this.urunData.id,
        bagDurum,
      };

      this.$store
        .dispatch('teklifAciklamaYonetim/fetchUrunTeklifAciklamas', queryParams)
          .then(response => {
            if (response.statusCode === 200) {
              if (bagDurum) this.bagliList = response.result;
              else this.bagliOlmayanList = response.result;
            } else {
              AlertService.error(this, response.message);
            }
          });
    },

    baglaToggle(teklifAciklamaId, isBagla) {
      const queryParams = {
        urun_Id: this.urunData.id,
        teklifAciklama_Id: teklifAciklamaId,
      };

      let storeName = 'teklifAciklamaYonetim/urunTeklifAciklamaBagla';
      if (!isBagla) {
        storeName = 'teklifAciklamaYonetim/urunTeklifAciklamaBagKopar';
      }
      this.$store
        .dispatch(storeName, queryParams)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              });
              this.fetchBagli(true);
              this.fetchBagli(false);
            } else {
              AlertService.error(this, response.message);
            }
          });
    },

    addNew() {
      this.modalObject.item = { };
      this.modalObject.showModal = true;
    },
  },
  mounted() {
    this.fetchBagli(true);
    this.fetchBagli(false);
  },
};
</script>

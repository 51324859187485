<template>
  <b-card>
    <b-row class="mb-3">
      <b-col md="6">
        <div class="demo-inline-spacing">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="openUpload()">
            <feather-icon icon="UploadIcon" class="mr-50" size="20" />
            {{ $t('Ürün Resim Yükle') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <hr>
    </b-row>
    <b-form
      ref="form"
      class="repeater-form"
    >
      <!-- Row Loop -->
      <b-row
        v-for="urunResim in urunResimList"
        :key="urunResim.id"
        ref="row">
        <!-- Item Name -->
        <b-col
          cols="12"
          md="4"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="bg-light-primary rounded-top text-center">
            <b-img
              :src="urunResim.sResimUrl"
              alt="Meeting Pic"
              height="160"
            />
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-primary btn-image-update"
              class="mt-0 mt-md-2"
              @click="openChangeUpload(urunResim)"
            >
              <feather-icon icon="PaperclipIcon" class="mr-25" />
              <span>
                {{ $t('Değiştir') }}
                </span>
            </b-button>
          </div>
        </b-col>

        <!-- Cost -->
        <b-col md="3">
          <b-form-group class="mb-2">
            <label label-for="sDosyaAdi">{{ $t('Dosya Adı') }}</label>
            <b-form-input id="sDosyaAdi" type="text" 
              v-model="urunResim.sDosyaAdi" 
              disabled />
          </b-form-group>
          <b-form-group>
            <label label-for="urunResimTuru">{{ $t('Resim Türü') }}</label>
            <v-select
              v-model="urunResim.urunResimTuru"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="urunResimTurOptions"
              :reduce="(val) => val.value"
              input-id="urunResimTuru" />
          </b-form-group>
        </b-col>

        <!-- Quantity -->
        <b-col md="3">
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group>
            <label label-for="sResimAciklama">{{ $t('Açıklama') }}</label>
                <b-form-textarea
                  v-model="urunResim.sResimAciklama"
                  id="sResimAciklama"
                  :placeholder="$t('Açıklama')"
                  rows="2" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group>
            <label label-for="sDilKodu">{{ $t('Dil') }}</label>
                <v-select
                  v-model="urunResim.sDilKodu"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dilOptions"
                  :reduce="(val) => val.value"
                  input-id="selectedLanguage"
                  placeholder="Dil Seçiniz" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group>
            <label class="mb-1" label-for="isActive">{{ $t('Aktif Durumu') }}</label>
                <b-form-checkbox
                    v-model="urunResim.isActive"
                    class="custom-control-primary">
                    {{ $t('Aktif Durumu') }}
                  </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <!-- Remove Button -->
        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-success"
            class="mt-0 mr-1 mt-md-2 btn-sm"
            @click="copyUrl(urunResim)"
          >
            <feather-icon icon="CopyIcon" class="mr-25" />
            <span>
              {{ $t('URL Kopyala') }}
              </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary btn-sm"
            class="mt-0 mr-2 mt-md-2"
            @click="saveUrunResim(urunResim)"
          >
            <feather-icon icon="EditIcon" class="mr-25" />
            <span>
              {{ $t('Güncelle') }}
              </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger btn-sm"
            class="mt-0 mt-md-2"
            @click="deleteUrunResim(urunResim)">
            <feather-icon icon="XIcon" class="mr-25" />
            <span>
              {{ $t('Kaldır') }}
              </span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import ApiService from '@/common/api.service';
import AlertService from '@/common/alert.service';
import { useClipboard } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// Options
import StaticOptions from '@/common/options/StaticOptions';

export default {
  directives: {
    Ripple,
  },
  props: {
    urunData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dilOptions: StaticOptions.dilOptions,
      urunResimTurOptions: StaticOptions.urunResimTurOptions,
      urunResimList: [],
    }
  },
  methods: {
    fetchUrunResimList() {
      const queryParams = {
        urunId: this.urunData.id,
      };

      ApiService.post('manage/file/urunresim/getall', queryParams, this)
        .then(response => {
          if (response.statusCode === 200) {
            response.result = response.result.map(item => {
              item.isUpdate = false
              return item
            })
            this.urunResimList = response.result;
          } else {
            AlertService.error(this, response.message);
          }
        });
    },
    
    openUpload() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';

      fileInput.addEventListener('change', event => {
        this.uploadUrunResim(event);
      });

      fileInput.click();
    },
    uploadUrunResim(event) {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      formData.append('folderName', 'urun-resim');
      formData.append('urunId', this.urunData.id);
      formData.append('urunResimTuru', 0);

      ApiService.upload('manage/file/urunresim/upload', formData, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: 'CheckCircleIcon',
                variant: 'danger',
              },
            });
            this.fetchUrunResimList()
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    openChangeUpload(urunResim) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';

      fileInput.addEventListener('change', event => {
        this.changeUrunResim(event, urunResim);
      });

      fileInput.click();
    },
    changeUrunResim(event, urunResim) {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      formData.append('folderName', 'urun-resim');
      formData.append('urunResimId', urunResim.id);

      ApiService.upload('manage/file/urunresim/update/file', formData, this)
        .then(response => {
          if (response.statusCode === 200) {
            urunResim.sResimUrl = response.result
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    saveUrunResim(urunResim) {
      ApiService.post('manage/file/urunresim/update', urunResim, this)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: 'CheckCircleIcon',
              variant: 'primary',
            },
          })
        });
    },
    deleteUrunResim(urunResim) {
      ApiService.get(`manage/file/urunresim/delete/${urunResim.id}`, {}, this)
        .then(response => {
          this.fetchUrunResimList();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          });
        });
    },

    copyUrl(urunResim) {
      const { copy } = useClipboard()
      copy(urunResim.sResimUrl)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'URL Kopyalandı.',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })
    },
  },
  mounted() {
    this.fetchUrunResimList();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.btn-image-update {
  position: absolute !important;
  bottom: 0px;
  right: 15px;
}
</style>

<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Genel') }}
          </span>
      </template>

      <urun-genel
        v-if="urunGenelData"
        :urun-genel-data="urunGenelData" />
    </b-tab>
    <!--/ general tab -->

    <!-- Acıklama tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Ürün Açıklama') }}
          </span>
      </template>

      <urun-aciklama
        v-if="urunGenelData"
        :urun-data="urunGenelData" />
    </b-tab>
    <!--/ Acıklama tab -->

    <!-- Resim tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ImageIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Ürün Resimleri') }}
          </span>
      </template>

      <urun-resim 
        v-if="urunGenelData"
        :urun-data="urunGenelData" />
    </b-tab>
    <!--/ Resim tab -->

    <!-- Ürün Teklif Açıklama tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="AirplayIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Ürün Gereksinim Açıklama') }}
          </span>
      </template>

      <urun-teklif-aciklama 
        v-if="urunGenelData"
        :urun-data="urunGenelData" />
    </b-tab>
    <!--/ Resim tab -->
  </b-tabs>
</template>

<script>
// Alert
import AlertService from '@/common/alert.service'
import UrunGenel from './UrunGenel.vue' 
import UrunAciklama from './UrunAciklama.vue' 
import UrunResim from './UrunResim.vue'
import UrunTeklifAciklama from './UrunTeklifAciklama.vue'

export default {
  components: {
    UrunGenel,
    UrunAciklama,
    UrunResim,
    UrunTeklifAciklama,
  },
  data() {
    return {
      urunGenelData: null,
    }
  },
  methods: {
    fetchUrun() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== -1) {
        this.$store.dispatch('fetchUrun', { id: this.$router.currentRoute.params.id })
          .then(response => {
            if (response.statusCode === 200) {
              this.urunGenelData = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },
  },
  created() {
    this.fetchUrun()
  },
}
</script>
